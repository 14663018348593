.formDocument1 {
    .formSubSection {

        .fields {

            .field {

                .ms-ChoiceField {
                    display: inline-block;
                    padding-right: 25px;
                }

                .imp {
                    color: red;
                }
            }
        }
    }
}