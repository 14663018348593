.dependentsection {
    width: 80%;
    margin: auto;

    .addDependentBTN {
        margin: 10px 0px;
        float: right;
    }

    .dependentActionIcons {
        float: right;

        .actionIcon {
            cursor: pointer;
        }

        .viewIcon {
            padding-right: 10px;
        }
    }
}