.ms-Panel-main {
    .ms-Panel-commands {
        padding-top: 10px;

        .panelHeader {
            padding-top: 0px;
            padding-bottom: 0px;
        }
    }
}

.submitMessage {
    width: 100%;
    background-color: green;
    padding: 15px 10px;
    text-align: center;
    color: white;

    .submitMSG {
        .adminLink {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}


.topScrollDiv {
    width: 100%;

    @media screen and (max-width: 480px) {
        overflow: auto;
    }

    .userProfileSection {
        width: 100%;

        @media screen and (max-width: 480px) {
            width: 800px;
            padding-bottom: 15px;
        }

        .userProfileNavigation {
            padding-right: 5px;

            .secHeader {
                font-weight: 600;
                font-size: 1.2em;
            }

            button {
                width: 200px;
                padding-right: 0px !important;
            }
        }

        .userProfileForm {
            vertical-align: top;
            margin-left: 20px;
            width: 100%;
            // border-radius: 50%;
            border-color: grey;
            border-width: 1px;
            border-style: solid;
            min-height: 300px;
            position: relative;

            @media screen and (max-width: 480px) {
                margin-left: 0px;
            }

            .formHeader {
                color: white;
                background-color: black;
                padding: 20px;
                text-align: center;
                font-weight: 600;
                font-size: 1.1em;

                @media screen and (max-width: 480px) {
                    padding: 10px;
                    font-size: initial;
                }
            }



            .topActionSection {
                clear: both;

                .actionSection {
                    // >div {
                    //     float: right;
                    // }

                    padding: 5px 10px 0px 10px;
                    // float: right;

                    .adminActionOnly {
                        margin-left: auto;

                        button {
                            // width: 200px;
                            text-align: left;
                        }
                    }

                    .bottomActions {
                        position: absolute;
                        bottom: 10px;
                        right: 10px;

                        .previousCall {
                            padding-right: 10px;
                        }

                        .saveCall {
                            // margin-left: auto;

                            .ms-Button-flexContainer {
                                flex-direction: row-reverse;
                            }
                        }
                    }

                }
            }


        }
    }
}

input[type='radio'] {
    accent-color: #467abd;
}

.ms-Checkbox.is-disabled>.ms-Checkbox-label>.ms-Checkbox-checkbox {
    background: rgb(200, 198, 196);
}

.fc-button,
button:focus {
    outline: none !important;
    box-shadow: none !important;
}

.fc-prev-button,
.fc-next-button {
    background-color: #36393a !important;
    border-color: #36393a !important;
}

.fc-dayGridMonth-button,
.fc-timeGridDay-button {
    background-color: #9a9c9c !important;
    border-color: #9a9c9c !important;
}

.fc-button-active {
    background-color: #36393a !important;
    border-color: #36393a !important;
}

.fc-today-button {
    background-color: #467abd !important;
    border-color: #467abd !important;
}

textarea:disabled,
input[type="text"][disabled],
input[type="number"][disabled] {
    color: #36393a !important;
}

.is-disabled .ms-Dropdown-title {
    color: #36393a !important;
}

div.ms-TextField-fieldGroup,
div.ms-Checkbox-checkbox,
.ms-Dropdown-title {
    border-radius: 0px !important;
}

.rightAlign {
    text-align: right;
}

.WhiteColor {
    color: white;
}

.fc-col-header-cell-cushion {
    color: #467abd !important;
}

.fc-daygrid-day-number {
    color: #36393a !important;
}

.fc-day-today {
    background-color: #cdcdce !important;
}

.eventJobOpened,
.eventJobClosed {
    .fc-daygrid-day-number {
        color: white !important;
    }
}

.Gray100 {
    background-color: #36393a !important;
}

.Gray75 {
    background-color: #686b6b !important;
}

.Gray50 {
    background-color: #9a9c9c !important;
}

.Gray25 {
    background-color: #cdcdce !important;
}

.Gray100 {
    color: #36393a !important;
}

.GrayColor75 {
    color: #686b6b !important;
}

.GrayColor50 {
    color: #9a9c9c !important;
}

.GrayColor25 {
    color: #cdcdce !important;
}

div.attachmentComponent>div span {
    color: #467abd !important;
}

button {
    border-radius: 0 !important;
}

.react-tabs__tab--selected {
    border-radius: 0 !important;
}

.Blue100 {
    background-color: #467abd !important;
}

.Blue75 {
    background-color: #749bce !important;
}

.Blue50 {
    background-color: #a2bcde !important;
}

.Blue25 {
    background-color: #d1deee !important;
}

.BlueColor100 {
    color: #467abd !important;
}

.BlueColor75 {
    color: #749bce !important;
}

.BlueColor50 {
    color: #a2bcde !important;
}

.BlueColor25 {
    color: #d1deee !important;
}


.welcomeHeading {
    font-size: 1.5rem;
    color: #467abd;
    margin: 0 0 20px 0;
}

.tableStyle {
    width: 900px;
    max-height: 195px;
    overflow-y: auto;
}

.tableStyle:hover {
    cursor: pointer;
}

.heading {
    font-weight: 600;
}

.linkButton {
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #8fb91c;
    border: none;
}

.bold {
    font-weight: bold;
    display: block;
}

.contactUs {
    width: 100%;
    text-align: right;
    padding: 20px;
}

.actionButtonContainer {
    width: 50%;
    padding: 10px;
}

.welcomeContainer {
    padding: 10px;
    width: 100%;

    @media screen and (max-width: 480px) {
        display: block;
        height: 100%;
    }
}

.flagButton {
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    width: 200px;
    height: 100px;

    &:active,
    &:hover,
    &:focus {
        border: none;
    }

    .flag {
        width: 100%;
        height: 100%;
    }

    @media screen and (max-width: 480px) {
        width: 100px;
        height: 50px;
    }
}


.animate {
    animation-duration: .8192s;
    animation-fill-mode: backwards;
    transform-style: preserve-3d;
}

/** intro */
@keyframes intro {
    from {
        opacity: 0;
        transform: perspective(500px) translate3d(0, 0, -50px);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.popIn {
    animation: intro 1s ease-out;
}