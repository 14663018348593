.modalDialogComment {
    .ms-Dialog-main {
        // min-width: 500px;

        @media screen and (max-width: 480px) {
            min-width: -webkit-fill-available !important;
        }

        .commenttxt {
            textarea {
                height: 120px;
            }
        }

        .foolter {
            text-align: right;
            padding-bottom: 20px;
            padding-top: 20px;
        }

        .dataContainerSection {
            .commentSection {
                .lblError {
                    color: red;
                }
            }

            .adminComments {
                width: 670px;

                .commentTBL {
                    table-layout: fixed;
                    border: none !important;

                    thead>tr {
                        position: relative;
                        display: block;
                    }

                    tbody {
                        display: block;
                        // height: 80px;
                        max-height: 200px;
                        overflow: auto;
                    }

                    .adminComment {
                        .index {
                            width: 50px;
                        }

                        .email {
                            width: 250px;
                            word-break: break-all;
                        }

                        .comment {
                            width: 350px;
                            word-break: break-all;
                        }
                    }
                }
            }
        }
    }
}

.appConfirmationBox {
    .ms-Dialog-main {
        max-width: fit-content;
    }
}

.freelancerMessageBox {
    .msgSuccess {
        background-color: green;
        color: white;
        padding: 15px;
    }

    .msgError {
        background-color: red;
        color: white;
        padding: 15px;
    }
}


/* The Modal (background) */
.modalFreelancer {
    // display: none; /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 10000000;
    /* Sit on top */
    padding-top: 100px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */


    /* Modal Content */
    .modalFreelancer-content {
        background-color: #fefefe;
        margin: auto;
        padding: 12px;
        border: 1px solid #888;
        width: 30%;

        .modalHeader {
            padding: 10px;
            background-color: gray;
        }

        .modalMessage {
            padding: 10px;
        }

        .modalFooter {
            text-align: right;
        }
    }

    /* The Close Button */
    .closeFreelancer {
        color: #aaaaaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
    }

    .closeFreelancer:hover,
    .closeFreelancer:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
    }
}