.mailConfirmationBox {
    .ms-Dialog-inner {

        .ms-Dialog-title {
            display: none;
        }

        .mailBody {
            .ms-TextField-fieldGroup {
                height: 150px;
            }
        }
    }

    .ms-Dialog-main {
        // max-width: fit-content;
        width: 500px !important;
        max-width: 500px !important;
        min-width: 500px !important;
    }

    .mailfooter {
        padding-bottom: 30px;
    }
}

.errorMailBody {
    color: red;
    font-weight: 600;
}