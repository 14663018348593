.appLoadingError {
    color: red;
    text-align: center;
    font-weight: 600;
    padding: 20px;
}

.locatization {
    // position: absolute;
    // top: -17px;
    // left: 25px;
    // z-index: 1000;

    .selected {
        font-weight: 600;
    }

    a {
        padding-left: 5px;
        padding-right: 5px;
        cursor: pointer;
    }
}

body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}

#root {
    width: 100%;
    min-height: 100vh;
    // display: flex;
}

/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.fullWidth {
    width: 100%;

}

.mainContent .fullWidth {
    width: 100%;

    @media screen and (max-width: 480px) {
        display: block;
        height: 100%;

    }
}

.submitWrapper {
    width: 100%;
    margin-top: 20px;
}

button.hiddenButton:disabled {
    background-color: white !important;
}

.straumannBlueButton {
    border: 1px solid #467abd;
    border-radius: 0px;
    // background-color: #467abd;
    background-color: #467abd !important;
    color: white;

    .ms-Icon {
        color: #fff;
    }

    &:hover {
        color: #fff;
        background-color: #2462A2;

        .ms-Icon {
            color: #fff;
        }
    }

    &:disabled {
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
    }
}

.ms-Nav-navItem i {
    color: red;
    font-weight: bold;
}

.FreelanceSummary {
    input.ms-TextField-field::placeholder {
        color: white !important;
    }

    .ms-TextField-errorMessage,
    .freelanceError {
        display: none;
    }
}

.stepWizard>div {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 140px);
}

.stepWizard .ms-FocusZone {
    overflow: visible;
}

.stepWizard>div>div {
    padding-left: 5px;
    padding-right: 15px;
    padding-bottom: 10px;
}

.tableStyle .ms-DetailsHeader {
    padding-top: 0px;
}

.ms-Dialog-inner {
    padding-bottom: 10px;
}

span.ms-Dropdown-title.ms-Dropdown-titleIsPlaceHolder {
    font-style: italic !important;
    color: #c7c7c7 !important;
}

fieldset {
    margin-top: 20px !important;
}

.fieldsetsection {
    position: relative;
    display: block;
    margin-inline-start: 2px;
    margin-inline-end: 2px;
    padding-block-start: 0.35em;
    padding-inline-start: 0.75em;
    padding-inline-end: 0.75em;
    padding-block-end: 0.625em;
    min-inline-size: min-content;
    border-width: 1px;
    border-style: groove;
    border-color: silver;
    -o-border-image: initial;
    border-image: initial;
    padding: 1em;
    padding-top: 0px !important;

    // margin-bottom: 1.5em;


}

.lineHeight {
    line-height: 50px;
}