.dependentActions {
    padding-top: 10px;
    float: right;

    .addBTN {
        margin-left: 10px;
        min-width: 150px;
    }

    .cancelBTN {
        margin-right: 5px;
        min-width: 150px;
    }
}