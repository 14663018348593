.appCalendar {
    position: relative;

    .toolCal {
        @media screen and (min-width:769px) {

            div:nth-child(2) {
                padding: 0px !important;
            }

            div:nth-child(4) {
                padding: 0px !important;
                width: 100px;

                div {
                    padding: 0px !important;

                    // margin: 0px !important;
                    // button {
                    //     padding: 0px !important;
                    //     margin: 0px !important;
                    // }
                }
            }
        }
    }

    .ms-DatePicker {
        width: 100% !important;
    }

    .appCalTextBox {
        float: left;
        width: 100%;
    }

    .calIcon {
        // float: right;
        // width: 10%;
        font-size: 1.5em;
        cursor: pointer;
        position: absolute;
        right: 10px;
    }
}