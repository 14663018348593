.formAdditional {
    .formSubSection {

        .fields {

            .field {


                .imp {
                    color: red;
                }
            }
        }
    }
}