.panelHeader {
  // background-color: lightgrey;
  padding: 20px 0px 20px 0px;
  // text-align: center;
}

.adminPanelContent {
  padding: 20px;
}

.manageUserProfiles {
  width: 100%;

  .searchSection {
    position: relative;

    @media screen and (max-width: 480px) {
      display: block;
    }

    .searchText {
      padding-right: 20px;

      @media screen and (max-width: 480px) {
        padding: 0px;
      }
    }
    .searchDate {
      padding-right: 20px;

      @media screen and (max-width: 480px) {
        padding: 0px;
      }
    }
    .searchOptions {
      padding-left: 20px;

      @media screen and (max-width: 480px) {
        padding: 0px;
      }

      .ms-Dropdown {
        @media screen and (max-width: 480px) {
          width: 100% !important;
        }
      }
    }

    .searchIcon {
      position: relative;

      .actionIcon {
        position: absolute;
        bottom: 0px;
        left: 20px;
        font-size: 2em;
        color: black;
        cursor: pointer;

        @media screen and (max-width: 480px) {
          position: initial;
        }
      }
    }
  }

  .userProfilesGrid {
    .ms-DetailsList-contentWrapper {
      max-height: 400px;
      overflow-y: auto;
      overflow-x: clip;
    }

    .actionIcon {
      cursor: pointer;
      padding-right: 10px;
    }
  }
}

.userInformationSection {
  .fields {
    .field {
      .label {
        font-weight: 600;
        padding: 10px 0px 2px 0px;
      }

      .value {
        width: 100%;
      }

      .imp {
        color: red;
      }
    }
  }

  .submitSec {
    .submitSecRow {
      .submitBTN {
        float: right;
        margin-top: 20px;
        width: 125px;
        background-color: black;
        color: white;
      }

      .cancelBTN {
        // float: right;
        margin-top: 20px;
        width: 125px;
        background-color: black;
        color: white;
      }
    }
  }
}
