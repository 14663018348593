.formDetails {
    .formSubSection {

        .fields {

            .field {
                .value {
                    .birthTextBox {
                        float: left;
                        width: 90%;
                    }

                    .birthCal {
                        float: right;
                        width: 10%;
                        font-size: 1.5em;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}