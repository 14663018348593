.formSection {
    clear: both;
    padding-bottom: 50px;

    .formFullSection {
        width: 100%;
    }

    .formSubSection {
        width: 50%;
        // padding: 0px 10px;


        .fields {
            padding: 10px 10px 10px 10px;

            .field {
                .label {
                    font-weight: 600;
                    padding: 10px 0px 2px 0px;
                }

                .value {
                    width: 100%;

                }

                .imp {
                    color: red;
                }
            }
        }
    }
}